.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.links {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}

a {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
}

a:hover {
  color: #2EABDC;
}

.blue {
  color: #2EABDC;
}